import React, { FC } from 'react'
import { CircularProgress } from '@mui/material'
import Logo from '../../../assets/images/logo.png'
import './loader.scss'

interface LoaderProps {
    transparent?: boolean
}

const Loader: FC<LoaderProps> = ({ transparent = false }) => {
    return (
        <div className={`loader ${transparent && 'transparent'}`}>
            <div className="loader-container">
                <CircularProgress size={92} thickness={2} />
                <img src={Logo} className="logo" alt="Logo" />
            </div>
        </div>
    )
}

export default Loader
