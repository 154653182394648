import { useEffect } from 'react'
import { Router } from './router'
import './scss/main.scss'

function App() {
    useEffect(() => {
        const script = document.createElement('script')
        script.src =
            'https://doris-embed.diceplatform.com/scripts/2/embed.min.js'
        script.async = true

        document.head.appendChild(script)
    }, [])
    return <Router />
}

export default App
